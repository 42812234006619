import { COMP_SELECTOR_PLACEHOLDER } from '../constants';

export const getDisplayModeStyle = (
  displayMode: string,
  aspectRatio: number,
  useNativeAspectRatio: boolean,
) => {
  if ('fitWidth' === displayMode) {
    if (useNativeAspectRatio) {
      return `${COMP_SELECTOR_PLACEHOLDER} {aspect-ratio: ${1 / aspectRatio};}`;
    }
    return (
      `${COMP_SELECTOR_PLACEHOLDER}::before {--aspect-ratio: ${aspectRatio};` +
      `content: attr(x);display: block;padding-top: calc(var(--aspect-ratio) * 100%);}`
    );
  }
  return '';
};
